// -------------------------------------------------------------
//   Main header
//   Header styles
// -------------------------------------------------------------

.main-header {
	position: fixed;
  width: 100%;
  height: 150px;
  top: 0;
  left: 0;
  z-index: 40;
  &.scrolled {
    height: 90px;
    .topbar {
      margin-top: -60px;
      @include transition(all .2s);
      @include media-breakpoint-down(sm) {
        margin-top: -110px;
      }
    }
    .mainbar {
      background: #fff;
      @include transition(all .2s);
      .mainbar-content {
        padding: 20px 0 10px 0;
      }
    }
  }
}
.topbar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px;
  height: 50px;
  background: #fff;
  margin-top: 0;
  @include transition(all .2s);
  @include media-breakpoint-down(md) {
    height: auto;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      display: inline-block;
      margin: 0 5px;
      a {
        color: map-get($theme-colors, secondary);
        text-decoration: none;
        font-size: .6rem;
        text-transform: uppercase;
        @include transition(color .2s);
        &:hover {
          color: map-get($theme-colors, primary);
          @include transition(color .2s);
        }
      }
    }
  }
  nav {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  #subsites-nav {
    ul {
      li {
        a {
          font-weight: 600;
          font-size: .7rem;
          &.active {
            color: map-get($theme-colors, primary);
          }
        }
      }
    }
  }
}
nav#secondary-nav {
  display: none;
}
.subsite-papeterie, .subsite-librairie, .subsite-imprimerie, .subsite-copies-impressions {
  nav#secondary-nav {
    display: block;
  }
}
.subsite-librairie {
  #banner {
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 250px;
      background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 28%, rgba(255,255,255,0) 100%);
      background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 28%,rgba(255,255,255,0) 100%);
      background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 28%,rgba(255,255,255,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );
    }
  }
}
.mainbar {
  background: transparent;
  position: relative;
  @include transition(all .2s);
  a.logo {
    img {
      width: 220px;
      height: 68px;
    }
  }
  .mainbar-content {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 0 0;
  }
}
