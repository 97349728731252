.page-blog,
.node-type-news,
.page-papeterie-blog,
.node-type-webform,
.node-type-contact,
.node-type-page,
.page-sitemap,
.node-type-promotion-manga,
.page-commande-confirmation,
.node-type-product {
  .content-banner {
    height: 350px;
    background-color: map_get($theme-colors, purple);
    position: relative;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    h1 {
      padding-top: 190px;
      position: relative;
      z-index: 2;
      @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
        padding: 130px 5px 10px 5px;
      }
    }
    h2 {
      position: relative;
      z-index: 2;
    }
    .breadcrumb {
      position: relative;
      z-index: 2;
    }
  }
  .mainbar {
    .mainbar-content {
      @include media-breakpoint-down(sm) {
        padding: 10px 0 0 0;
      }
    }
  }
  .trigger-menu {
    margin-top: 0;
    top: 25px;
  }
  .main-header {
    #nav {
      > ul {
        > li {
          > a,
          > .nolink {
            color: #fff;
          }
          &.highlight {
            a {
              color: map_get($theme-colors, primary);
            }
          }
        }
      }
    }
    a.logo {
      background-image: url(../images/logo-white.svg);
      width: 220px;
      height: 67px;
      @include media-breakpoint-down(sm) {
        width: 180px;
        height: 55px;
      }
      img {
        display: none;
      }
    }
    &.scrolled {
      .mainbar {
        .mainbar-content {
          @include media-breakpoint-down(sm) {
            padding: 10px 0 0 0;
          }
        }
      }
      a.logo {
        background-image: none;
        @include media-breakpoint-down(sm) {
          width: 180px;
          height: 55px;
        }
        img {
          display: block;
          max-width: 100%;
        }
      }
      #nav {
        > ul {
          > li {
            > a,
            > .nolink {
              color: map_get($theme-colors, middle);
            }
            &.highlight {
              > a {
                color: map_get($theme-colors, primary);
              }
            }
          }
        }
      }
    }
  }
  .view-content {
    margin-top: 50px;
    .news {
      margin: 0 100px 30px 100px;
      @include media-breakpoint-down(sm) {
        margin: 10px;
      }
      .news-pic {
        display: block;
        height: 300px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
      .date {
        text-transform: uppercase;
        color: map_get($theme-colors, primary);
        font-size: 0.7rem;
        font-weight: 600;
        margin: 20px 0 10px 0;
      }
      h2 {
        font-family: $font-family-sans-serif;
        color: #000;
        font-weight: 700;
        font-size: 1.1rem;
        a {
          color: #000;
          text-decoration: none;
        }
      }
      p {
        color: map_get($theme-colors, middle);
        font-size: 0.8rem;
      }
    }
  }
  .inner-content-cta {
    margin: 50px 100px;
    @include media-breakpoint-down(md) {
      margin: 10px;
    }
  }
}
.node-type-news {
  article {
    @extend .container;
    padding: 30px 0;
    @include media-breakpoint-down(sm) {
      padding: 20px;
    }
    img {
      max-width: 100%;
    }
    .back {
      a {
        text-decoration: none;
        &:before {
          content: "\f0d9";
          font-family: $font-awesome;
          font-weight: 600;
          margin-right: 5px;
        }
      }
    }
    .news-pic-base {
      float: left;
      margin-right: 20px;
    }
    .date {
      font-style: italic;
      font-size: 0.8rem;
    }
  }
}
