.subsite-librairie {
	#banner { 
		height: 950px;
		background-size: cover;
		background-position: top left;
		.banner-inner {
			@extend .container;
			text-align: center;
			padding-top: 230px; 
		}
		h1 {
			color: map_get($theme-colors, primary);
			font-weight: 600;
			font-size: 2rem;
			width: 500px;
			text-align: left;
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
			span {
				color: map_get($theme-colors, middle);
				font-weight: 600;
				font-size: 1.5rem;
				display: block;
				margin-top: 10px;
				margin-bottom: 40px; 
			}
		}
		p {
			text-align: left;
			a {
				&.btn {
					background-color: map_get($theme-colors, middle);
					border: 0;
					color: #fff;
					&:hover {
						background-color: map_get($theme-colors, primary);
					}
				}
			}
		}
	}
	#products {
		.product-title {
			.inner-product-title {
				h2 {
					color: map_get($theme-colors, middle);
				}
				.swiper-button-prev, .swiper-button-next {
					color: map_get($theme-colors, middle);
				}
			}
		}
	}
}