// -------------------------------------------------------------
//   Homepage
//   Homepage specific styles
// -------------------------------------------------------------
#activites {
  margin-top: -100px;
  position: relative;
  z-index: 15;
  .activite {
    display: block;
    height: 450px;
    text-decoration: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    @include transition(all .2s);
    @include media-breakpoint-down(md) {
      height: 300px;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.2);
      z-index: 1;
    }
    h2 {
      font-family: $font-family-fancy;
      color: #fff;
      position: relative;
      font-size: 1.5rem;
      padding: 0 40px;
      margin: 100px 0 0 0;
      height: 150px;
      z-index: 2;
      @include media-breakpoint-down(md) {
        height: 100px;
      }
    }
    p {
      display: inline-block;
      color: map_get($theme-colors, primary);
      background: #fff;
      font-weight: 600;
      font-size: 1rem;
      padding: 10px 30px;
      font-family: $font-family-fancy;
      position: relative;
      z-index: 2;
      @include transition(all .2s);
    }
    &:hover {
      -webkit-box-shadow: 0px 0px 25px 3px rgba(0,0,0,0.26);
      -moz-box-shadow: 0px 0px 25px 3px rgba(0,0,0,0.26);
      box-shadow: 0px 0px 25px 3px rgba(0,0,0,0.26);
      @include transition(all .2s);
      p {
        background: map_get($theme-colors, primary);
        color: #fff;
        @include transition(all .2s);
      }
    }
  }
}
#description {
  position: relative;
  z-index: 10;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  .description-content {
    position: relative;
    z-index: 1;
    padding: 50px 0 250px;
    p {
      font-family: $font-family-fancy;
      color: map_get($theme-colors, secondary);
      font-size: 1.2rem;
      text-align: center;
      line-height: 2rem;
      margin-bottom: 20px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    width: 700px;
    height: 100%;
    top: 0;
    left: calc(50% - 350px);
    background: map_get($theme-colors, light);
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}
#magasins {
  margin-top: -150px;
  position: relative;
  z-index: 15;
  .container {
    position: relative;
    height: 900px;
    @include media-breakpoint-down(md) {
      height: auto;
      display: flex;
      flex-wrap: wrap;
    }
    .magasin {
      position: absolute;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @include media-breakpoint-down(md) {
        position: static;
      }
      &:nth-child(1) {
        width: 50%;
        height: 470px;
        top: 100px;
        left: 0;
        z-index: 1;
        padding: 100px 50px;
        @include media-breakpoint-down(md) {
          height: 300px;
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
          padding: 30px 20px;
        }
      }
      &:nth-child(2) {
        width: 50%;
        height: 470px;
        top: 150px;
        left: 50%;
        z-index: 1;
        padding: 200px 50px 50px 50px;
        @include media-breakpoint-down(md) {
          height: 300px;
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
          padding: 30px 20px;
        }
      }
      &:nth-child(3) {
        width: 25%;
        height: 300px;
        top: 0;
        left: 50%;
        z-index: 2;
        padding: 30px 20px;
        @include media-breakpoint-down(md) {
          height: 300px;
          width: 50%;
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
      &:nth-child(4) {
        width: 25%;
        height: 300px;
        top: 450px;
        left: 25%;
        z-index: 2;
        padding: 30px 20px;
        @include media-breakpoint-down(md) {
          height: 300px;
          width: 50%;
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background: rgba(0,0,0,.3);
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
      .magasin-content {
        position: relative;
        z-index: 2;
        h2 {
          font-weight: 600;
          color: #fff;
          font-size: 1.2rem;
        }
        p {
          color: #fff;
        }
      }
    }
  }
}
#news {
  @include media-breakpoint-down(md) {
    margin-top: 40px;
  }
  .container {
    position: relative;
    padding-bottom: 50px;
  }
  h2 {
    text-align: center;
    color: map_get($theme-colors, secondary);
    font-family: $font-family-sans-serif;
    font-weight: 800;
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  .news-item {
    padding-left: 20px;
    padding-top: 30px;
    p {
      font-size: .8rem;
      max-width: 300px;
      a {
        color: map_get($theme-colors, lightpink);
      }
    }
    p.date {
      margin: 0;
      color: map_get($theme-colors, dark);
      font-style: italic;
      font-size: .7rem;
    }
    h3 {
      color: map_get($theme-colors, lightpink);
      font-weight: 700;
      font-size: 1rem;
      max-width: 300px;
      margin-bottom: 20px;
      a {
        color: map_get($theme-colors, lightpink);
        text-decoration: none;
      }
    }
  }
  .news {
    position: relative;
    z-index: 2;
  }
  .swiper-container {
    padding: 60px 0 0 0;
    @include media-breakpoint-down(sm) {
      padding: 10px 0 0 0;
    }
  }
  .all-link {
    position: absolute;
    right: 10px;
    bottom: 0;
    text-decoration: none;
    font-weight: 600;
    font-size: .9rem;
  }
  .swiper-slide {
    .news-pic {
      display: block;
      width: 100%;
      height: 400px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &:after {
      content: '';
      display: block;
      width: 60%;
      height: 100%;
      background: map_get($theme-colors, light);
      position: absolute;
      top: -50px;
      right: 0;
      z-index: 1;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
  .swiper-button-prev, .swiper-button-next {
    background-image: none;
    width: auto;
    height: auto;
    padding: 20px 25px;
    background-color: rgba(255,255,255,.5);
    border-radius: 100%;
    outline: none;
    -webkit-box-shadow: 0px 0px 28px -6px rgba(0,0,0,0.26);
    -moz-box-shadow: 0px 0px 28px -6px rgba(0,0,0,0.26);
    box-shadow: 0px 0px 28px -6px rgba(0,0,0,0.26);
    &:hover {
      background-color: rgba(255,255,255,1);
    }
  }
  .swiper-button-prev {
    left: auto;
    right: -10px;
  }
  .swiper-button-next {
    right: -10px;
    top: calc(50% + 70px);
  }
}
#products {
  display: flex;
  flex-wrap: wrap;
  margin-top: -275px;
  @include media-breakpoint-down(sm) {
    overflow-x: hidden;
    margin-top: -150px;
  }
  .product-title {
    width: 30%;
    text-align: right;
    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: left;
    } 
    .inner-product-title {
      display: inline-block;
      width: 200px;
      margin-right: 50px;
      position: relative;
      @include media-breakpoint-down(md) { 
        width: 150px;
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      h2 {
        color: #fff;
        text-align: left;
        font-weight: 500;
        font-size: 1.8rem;
        letter-spacing: 1px;
        @include media-breakpoint-down(sm) {
          padding-left: 20px;
          padding-bottom: 20px;
        }
      }
      .swiper-button-prev, .swiper-button-next {
        background-image: none;
        top: 120px;
        color: #fff;
        font-size: .9rem;
        width: auto;
        left: auto;
        padding: 10px 15px;
        border-radius: 100%;
        background: rgba(255,255,255,0);
        outline: none;
        @include transition(all .35s);
        @include media-breakpoint-down(sm) {
          top: 100px;
        }
        &:hover {
          background: rgba(255,255,255,1);
          color: #000;
          @include transition(all .35s);
        }
      }
      .swiper-button-prev {
        left: 0;
      }
      .swiper-button-next {
        left: 50px;
        right: auto;
      }
    }
  }
  .product-items {
    width: 70%;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  .swiper-products {
    padding-bottom: 100px;
    padding-left: 20px;
    height: 600px;
    @include media-breakpoint-down(md) {
      height: auto;
      padding-bottom: 20px;
    }
  }
  .swiper-slide {
    width: 330px;
    @include media-breakpoint-down(sm) {
      width: 80%;
    }
    a {
      display: block;
      height: 470px;
      margin-top: 40px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      text-decoration: none;
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @include transition(all .2s);
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 50%;
        position: absolute;
        z-index: 1;
        background-color: rgba(255,255,255,0);
        top: 50%;
        left: 0;
      }
      h2 {
        display: inline-block;
        padding: 5px;
        font-size: .9rem;
        font-weight: 600;
        color: #fff;
        background-color: #000;
        margin-top: 220px;
        position: relative;
        z-index: 2;
        @include transition(all .2s);
      }
      p {
        position: relative;
        z-index: 2;
        font-size: .8rem;
        padding: 10px 20px;
        opacity: 0;
        @include transition(all .2s);
      }
    }
    &:hover {
      a {
        height: 550px;
        margin-top: 0;
        text-align: center;
        -webkit-box-shadow: 0px 0px 37px -4px rgba(0,0,0,0.6);
        -moz-box-shadow: 0px 0px 37px -4px rgba(0,0,0,0.6);
        box-shadow: 0px 0px 37px -4px rgba(0,0,0,0.6);
        @include transition(all .2s);
        &:after {
          background-color: rgba(255,255,255,1);
        }
        h2 {
          margin-top: 260px;
          @include transition(all .2s);
        }
        p {
          opacity: 1;
          @include transition(all .2s);
        }
      }
    }
  }
}
#info {
  margin: 30px 0;
  @include media-breakpoint-down(sm) {
    margin: 15px 0;
  }
  p {
    font-weight: 600;
    color: map_get($theme-colors, lightpink);
    font-size: 1.3rem;
    padding: 30px 0 20px 0;
    @include media-breakpoint-down(sm) {
      padding: 10px 0;
    }
  }
  a {
    background: map_get($theme-colors, lightpink);
    color: #fff;
    font-weight: 600;
    display: inline-block;
    text-decoration: none;
    padding: 5px 10px;
    font-size: .9rem;
  }
  h2 {
    margin-top: 20px;
  }
}
#video {
  padding: 10px 0;
  @include media-breakpoint-down(sm) {

  }
}
#la-ciaco {
  position: relative;
  padding: 40px 0 0 0;
  h2 {
    position: absolute;
    top: 50px;
    width: 50%;
    font-size: 4rem;
    color: map_get($theme-colors, lightblue);
    z-index: 2;
    @include media-breakpoint-down(sm) {
      width: 100%;
      position: static;
      font-size: 2rem;
    }
  }
  .content {
    margin-left: 280px;
    @include media-breakpoint-down(sm) {
      margin-left: 0;
    }
    img {
      margin-bottom: 20px;
    }
    p {
      font-size: .9rem;
      color: map_get($theme-colors, middle);
    }
    a.btn {
      color: map_get($theme-colors, lightblue);
      border: 1px solid map_get($theme-colors, lightblue);
    }
  }
}