.node-type-liste {
  .content-banner {
    height: 430px;
    h1 {
      padding-top: 170px;
      padding-bottom: 10px;
    }
    p {
      margin-bottom: 5px;
      color: #fff;
      font-size: 1.1rem;
    }
  }
  .top-liste {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    @include media-breakpoint-down(md) {
      display: block;
    }
    h2 {
      color: #fff;
      font-size: 1.4rem;
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
    .back {
      &:before {
        content: "\f060";
        margin-right: 10px;
        font-family: $font-awesome;
        font-weight: 700;
        color: #fff;
      }
      a {
        color: #fff;
        text-decoration: none;
        border-bottom: 1px solid #fff;
        @include transition(all 0.2s);
        &:hover {
          border: 0;
          @include transition(all 0.2s);
        }
      }
    }
  }
}
.liste-bloc {
  background-color: map_get($theme-colors, middle);
  padding: 20px 90px;
  margin-top: -150px;
  @include media-breakpoint-down(md) {
    padding: 20px;
  }
  p {
    color: #fff;
  }
  .form-item-order-shipping {
    margin-bottom: 0;
  }
  .table-responsive-wrapper {
    position: relative;
    @include media-breakpoint-down(md) {
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../images/icon-swipe.svg);
        background-repeat: no-repeat;
        background-color: rgba(0, 0, 0, 0.4);
        background-position: center 40px;
        background-size: 25%;
        opacity: 0.9;
        z-index: 2;
      }
      &.active {
        &:before {
          display: none;
        }
      }
    }
  }
  table {
    display: table;
    @include media-breakpoint-down(lg) {
      display: block;
    }
    thead {
      width: 100%;
      background-color: #a7b0d3;
      th {
        border: 0;
        background-color: #a7b0d3;
        color: #fff;
        font-size: 0.8rem;
        @include media-breakpoint-down(md) {
          padding: 0.5rem;
        }
        &:nth-child(1) {
          width: 400px;
          @include media-breakpoint-down(md) {
            width: auto;
          }
        }
        &:nth-child(4) {
          width: 150px;
          @include media-breakpoint-down(md) {
            width: auto;
          }
        }
        &.prix-u {
          min-width: 150px;
          @include media-breakpoint-down(md) {
            min-width: auto;
          }
        }
        &.total {
          text-align: right;
        }
      }
    }
    tbody {
      width: 100%;
      background-color: #fff;
      tr {
        td {
          border: 0;
          background-color: #fff;
          font-size: 0.8rem;
          color: map_get($theme-colors, middle);
          .liste-pic-wrapper {
            display: inline-block;
          }
          .liste-pic {
            width: 50px;
            height: 50px;
            background-color: #fff;
            border: 1px solid darken(map_get($theme-colors, light), 10%);
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .liste-pic img {
            max-width: 100%;
            max-height: 50px;
            border: 1px solid map_get($theme-colors, light);
          }
          .liste-content-wrapper {
            margin-left: 5px;
          }
          a {
            color: map_get($theme-colors, middle);
            font-size: 0.9rem;
            text-decoration: none;
          }
          select,
          input[type="text"] {
            font-size: 0.85rem;
            height: 30px;
          }
          select {
            margin-right: 10px;
            width: 120px;
          }
          .title {
            margin-right: 10px;
            width: 120px;
          }
          .description {
            font-size: 0.8rem;
            color: map_get($theme-colors, middle);
          }
          .var-item,
          .novar-item {
            display: flex;
            margin-bottom: 10px;
            justify-content: space-between;
            .qty-check {
              margin-left: -2px;
              @include media-breakpoint-down(md) {
                display: flex;
              }
            }
            .cart-delete {
              border: 0;
              color: #a7b0d3;
              background-color: lighten(#a7b0d3, 22%);
              font-size: 0.75rem;
              padding: 9px 10px 0;
              border-radius: 100%;
              margin-left: 5px;
              line-height: 1;
              &:hover {
                color: map_get($theme-colors, primary);
              }
            }
            .min,
            .plus {
              height: 15px;
              display: block;
              padding: 1px 5px;
              border: 1px solid darken(map_get($theme-colors, light), 10%);
              font-size: 0.75rem;
              background-color: #fff;
              color: map_get($theme-colors, middle);
              line-height: 1;
              @include media-breakpoint-down(md) {
                height: 30px;
                padding: 10px;
              }
              &:hover {
                color: map_get($theme-colors, primary);
              }
            }
            .min {
              @include media-breakpoint-down(md) {
                order: 1;
              }
            }
            .plus {
              @include media-breakpoint-down(md) {
                order: 2;
              }
            }
            input {
              padding: 0 10px;
              width: 50px;
              text-align: center;
              &:disabled {
                cursor: initial;
                background-color: #fff;
              }
            }
          }
          .form-item {
            margin-bottom: 0;
          }
          .add-var {
            input {
              font-size: 0.8rem;
              background-color: #f6f7fb;
              color: #a7b0d3;
              border: 0;
              padding: 3px 20px;
              border-radius: 5px;
            }
            a.addvar-plus {
              color: #a7b0d3;
              padding: 5px 15px;
              background-color: lighten(#a7b0d3, 22%);
              font-weight: 700;
              border-radius: 20px;
              font-size: 0.8rem;
              margin: 5px 0;
              &:after {
                content: "\f0d7";
                display: inline-block;
                font-family: $font-awesome;
                margin-left: 5px;
              }
              &.active {
                &:after {
                  content: "\f0d8";
                }
              }
            }
          }
          s {
            color: map_get($theme-colors, primary);
          }
          .no-stock {
            background-color: #c00;
            color: #fff;
            display: inline-block;
            padding: 0 5px;
            margin-left: 5px;
            border-radius: 10px;
            font-size: 0.7rem;
            font-weight: 700;
          }
          &:last-child {
            text-align: right;
          }
          &:first-child {
            .liste-pic-content {
              display: flex;
              @include media-breakpoint-down(md) {
                flex-wrap: wrap;
              }
            }
          }
          &.qty {
            white-space: nowrap;
          }
        }
        &.odd {
          td {
            background-color: map_get($theme-colors, light);
          }
        }
        &.variation {
          display: none;
          &.active {
            display: table-row;
          }
          td {
            padding: 0 0.75rem 5px 0.75rem;
          }
        }
      }
    }
  }
  .modal {
    .modal-dialog {
      max-width: 1200px;
      .modal-content {
        .modal-body {
          border-radius: 0;
          padding: 0;
          .close {
            color: #000;
            opacity: 1;
            font-size: 1.5rem;
            padding: 7px 10px;
            background-color: #fff;
            border-radius: 100%;
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 1;
          }
          .product-modal-content {
            padding: 80px 50px;
            background-color: map-get($theme-colors, light);
            h2 {
              font-family: $font-family-sans-serif;
              color: map-get($theme-colors, primary);
              font-size: 1rem;
              text-transform: uppercase;
              font-weight: bold;
              margin-bottom: 10px;
            }
          }
          .price {
            font-size: 1.2rem;
            margin-bottom: 40px;
          }
          p {
            margin-bottom: 5px;
            font-size: 0.9rem;
          }
          .product-modal-image {
            padding: 20px;
            @include media-breakpoint-down(md) {
              margin-top: 20px;
            }
            .swiper-modal {
              .swiper-slide {
                height: 450px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                @include media-breakpoint-down(md) {
                  height: 300px;
                }
                img {
                  max-width: 100%;
                  max-height: 450px;
                  @include media-breakpoint-down(md) {
                    max-height: 300px;
                  }
                }
              }
              .swiper-button-next {
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23e6007e'%2F%3E%3C%2Fsvg%3E");
              }
              .swiper-button-prev {
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23e6007e'%2F%3E%3C%2Fsvg%3E");
              }
            }
          }
        }
      }
      img {
        max-width: 100%;
      }
    }
  }
  input[type="submit"]:disabled {
    cursor: initial;
  }
}
.end-bloc {
  display: flex;
  justify-content: space-between;
  color: #fff;
  @include media-breakpoint-down(md) {
    display: block;
  }
  p {
    color: #fff;
  }
  ul {
    padding-left: 0;
    li {
      color: #fff;
    }
  }
  .end-bloc-text {
    width: 70%;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  .end-bloc-total {
    width: 25%;
    text-align: right;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    p {
      font-weight: 700;
    }
    .cart-reset {
      @extend .btn;
      background-color: #a7b0d3;
      color: #fff;
      margin-bottom: 10px;
      @include media-breakpoint-down(md) {
        margin-bottom: 0;
        margin-right: 10px;
      }
    }
  }
}
.cart-box {
  position: fixed;
  top: 200px;
  right: 0;
  z-index: 10;
  display: inline-block;
  a {
    display: inline-block;
    padding: 20px;
    background-color: #fff;
    position: relative;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    -webkit-box-shadow: 0px 19px 19px -5px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: 0px 19px 19px -5px rgba(0, 0, 0, 0.37);
    box-shadow: 0px 19px 19px -5px rgba(0, 0, 0, 0.37);
    i {
      font-size: 1.5rem;
      color: #000;
      @include transition(all 0.2s);
    }
    span {
      position: absolute;
      top: 5px;
      left: -10px;
      display: inline-block;
      background-color: map_get($theme-colors, primary);
      color: #fff;
      padding: 5px 7px;
      border-radius: 100%;
      font-size: 0.85rem;
      font-weight: 700;
      line-height: 1;
    }
    &:hover {
      i {
        color: map_get($theme-colors, primary);
        @include transition(all 0.2s);
      }
    }
  }
}
.node-type-liste {
  .breadcrumb {
    display: none;
  }
}
.node-outlet,
.node-arcenciel {
  .liste-bloc {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    margin-top: -100px;
    p {
      color: map-get($theme-colors, dark);
    }
    form {
      margin-top: 80px;
      > div {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .outlet-item {
          width: 48%;
          padding: 20px;
          border: 1px solid darken(map-get($theme-colors, light), 10%);
          display: flex;
          background-color: #fff;
          justify-content: space-between;
          margin-bottom: 20px;
          @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
          }
          @include media-breakpoint-down(md) {
            width: 100%;
          }
          .liste-pic-wrapper {
            width: 48%;
            @include media-breakpoint-down(lg) {
              width: 100%;
            }
            .liste-pic {
              position: relative;
              .liste-percent {
                position: absolute;
                top: -10px;
                right: -5px;
                font-size: 0.8rem;
                color: #fff;
                background-color: map-get($theme-colors, primary);
                padding: 7px 3px;
                border-radius: 100%;
                font-weight: 700;
              }
            }
            img {
              max-width: 100%;
            }
          }
          .liste-content-wrapper {
            width: 48%;
            @include media-breakpoint-down(lg) {
              width: 100%;
              margin-top: 10px;
            }
            .variation {
              display: none;
              &.active {
                display: block;
              }
            }
            a.outlet-title {
              font-weight: 700;
              text-decoration: none;
              display: inline-block;
              margin-bottom: 5px;
            }
            .description {
              font-size: 0.85rem;
              margin-bottom: 10px;
            }
            .var-item,
            .novar-item {
              display: flex;
              margin-bottom: 10px;
              justify-content: flex-end;
              flex-wrap: wrap;
              align-items: flex-start;
              @include media-breakpoint-down(lg) {
                justify-content: flex-start;
              }
              .title {
                width: 130px;
              }
              .no-stock {
                background-color: #c00;
                color: #fff;
                display: inline-block;
                padding: 0 5px;
                margin-left: 5px;
                border-radius: 10px;
                font-size: 0.7rem;
                font-weight: 700;
              }
              .qty-check {
                margin-left: -2px;
                width: 20px;
              }
              .form-item {
                width: 51px;
              }
              .cart-delete {
                border: 0;
                color: #a7b0d3;
                background-color: lighten(#a7b0d3, 22%);
                font-size: 0.75rem;
                padding: 9px 10px;
                border-radius: 100%;
                margin-left: 5px;
                line-height: 1;
                width: 30px;
                &:hover {
                  color: map_get($theme-colors, primary);
                }
              }
              .min,
              .plus {
                height: 15px;
                display: block;
                padding: 1px 5px;
                border: 1px solid darken(map_get($theme-colors, light), 10%);
                font-size: 0.75rem;
                background-color: #fff;
                color: map_get($theme-colors, middle);
                line-height: 1;
                &:hover {
                  color: map_get($theme-colors, primary);
                }
              }
              .form-item {
                margin-bottom: 0;
              }
              .form-select {
                font-size: 0.8rem;
                height: 30px;
              }
              input {
                padding: 0 10px;
                width: 50px;
                height: 30px;
                text-align: center;
                font-size: 0.8rem;
                &:disabled {
                  cursor: initial;
                  background-color: #fff;
                }
              }
            }
            .add-var {
              margin-bottom: 10px;
              margin-top: 10px;
              @include media-breakpoint-down(xl) {
                margin: 3px 0 0 10px;
              }
              input {
                font-size: 0.8rem;
                background-color: #f6f7fb;
                color: #a7b0d3;
                border: 0;
                padding: 3px 20px;
                border-radius: 5px;
              }
              a.addvar-plus {
                color: #a7b0d3;
                padding: 5px 15px;
                background-color: lighten(#a7b0d3, 22%);
                font-weight: 700;
                border-radius: 20px;
                font-size: 0.8rem;
                margin: 5px 0;
                text-decoration: none;
                &:after {
                  content: "\f0d7";
                  display: inline-block;
                  font-family: $font-awesome;
                  margin-left: 5px;
                }
                &.active {
                  &:after {
                    content: "\f0d8";
                  }
                }
              }
            }
            .outlet-prices {
              display: flex;
              justify-content: space-between;
              margin-bottom: 10px;
              padding-bottom: 10px;
              border-bottom: 1px solid darken(map-get($theme-colors, light), 10%);
              .label {
                display: block;
                font-size: 0.8rem;
                color: map_get($theme-colors, middle);
                font-weight: 500;
              }
              .outlet-item-price {
                width: 48%;
                color: map_get($theme-colors, primary);
                font-weight: 600;
                font-size: 1rem;
              }
              .outlet-item-total-price {
                width: 48%;
                color: map_get($theme-colors, primary);
                font-weight: 600;
                font-size: 1rem;
              }
            }
            s {
              color: map_get($theme-colors, middle);
            }
          }
        }
      }
    }
  }
  .end-bloc {
    width: 100%;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid darken(map-get($theme-colors, light), 10%);
    p {
      color: map-get($theme-colors, middle);
    }
    ul {
      li {
        color: map-get($theme-colors, middle);
      }
    }
  }
}
.node-selections {
  overflow-x: hidden;
  .content-banner {
    height: 350px;
  }
  .liste-bloc {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    padding-top: 0;
    p {
      color: map-get($theme-colors, dark);
    }
  }
  .top-liste {
    background-color: map-get($theme-colors, lightpurple);
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
      justify-content: center;
    }
    p {
      color: #fff;
      a {
        font-weight: 600;
      }
    }
    form {
      display: flex;
      align-items: center;
      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        justify-content: center;
      }
      label {
        color: #fff;
        font-size: 0.9rem;
        margin-right: 5px;
      }
      select {
        margin-right: 5px;
        border: 0;
        color: map-get($theme-colors, primary);
        font-weight: 600;
        padding: 15px;
        @include media-breakpoint-down(md) {
          margin: 10px 0;
        }
      }
      button {
        border-radius: 0;
      }
    }
    &:before {
      content: "";
      display: block;
      width: 9999px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 100%;
      background-color: map-get($theme-colors, lightpurple);
    }
    &:after {
      content: "";
      display: block;
      width: 9999px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 100%;
      background-color: map-get($theme-colors, lightpurple);
    }
  }
  .products-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 40px 0;
    align-items: stretch;
    .product {
      width: 48%;
      height: auto;
      border: 1px solid map-get($theme-colors, light);
      margin-bottom: 40px;
      position: relative;
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
      a {
        display: flex;
        padding: 20px;
        text-decoration: none;
        @include media-breakpoint-down(md) {
          flex-wrap: wrap;
        }
        .product-pic {
          width: 50%;
          text-align: center;
          @include media-breakpoint-down(md) {
            width: 100%;
            margin-bottom: 10px;
          }
          img {
            max-width: 100%;
            max-height: 250px;
          }
        }
        .product-content {
          width: 50%;
          padding-left: 40px;
          @include media-breakpoint-down(md) {
            width: 100%;
          }
          .promo {
            display: flex;
            width: 50px;
            height: 50px;
            border-radius: 100%;
            background-color: map-get($theme-colors, primary);
            color: #fff;
            font-weight: 600;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 2px;
            left: calc(50% - 25px);
          }
        }
        h2 {
          font-family: $font-family-sans-serif;
          font-size: 1.1rem;
          font-weight: 700;
          color: map-get($theme-colors, primary);
        }
        .product-item-price {
          display: flex;
          flex-wrap: wrap;
          .label {
            display: block;
            font-size: 1rem;
            color: map-get($theme-colors, middle);
            font-weight: 600;
            margin-bottom: 5px;
            width: 100%;
            order: 1;
          }
          s {
            font-size: 1.1rem;
            color: map-get($theme-colors, middle);
            margin-left: 5px;
            position: relative;
            top: -5px;
            order: 3;
          }
          .price-number {
            font-size: 2rem;
            font-weight: 600;
            order: 2;
          }
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .products-text-end {
    text-align: center;
    ul {
      li {
        list-style: none;
        &:before {
          content: "-";
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
  }
}
.page-papeterie-outlet {
  .content-banner {
    height: 380px;
  }
  h1 {
    padding-top: 190px;
  }
}
.node-arcenciel {
  .end-bloc-total {
    width: 100%;
    padding: 20px 0;
    margin: 20px 0 0 0;
    border-top: 2px solid map-get($theme-colors, light);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
    p {
      margin: 0 20px 0 10px;
    }
    a {
      margin: 0 10px;
    }
  }
}
