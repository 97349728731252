// -------------------------------------------------------------
//   Main layout
//   Defines basic main styles in layout
// -------------------------------------------------------------

body {
	background-color: #fff;
	color: #292b2c;
	font-size: 1rem;
	line-height: 1.5em;
  overflow-x: hidden;
}
main {
  @include media-breakpoint-down(md) {
    overflow-x: hidden;
  }
}
.iframe-embed {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  padding-top: percentage(9 / 16);
  overflow: hidden;
  &::before {
    display: block;
    content: "";
  }

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
.message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  opacity: .8;
  z-index: 50;
}
.page-search404 {
  .content-banner {
    background: map_get($theme-colors, purple);
  }
  ul.action-links {
    @extend .container;
    margin-top: 40px;
  }
  .search-form {
    @extend .container;
    input[type='submit'] {
      margin: 5px 0 10px;
    }
  }
  h2 {
    @extend .container;
  }
  main ul {
    @extend .container;
  }
  main ol {
    @extend .container;
  }
}
.page-user {
  .content-banner {
    height: 200px;
    background: darken(map_get($theme-colors, light), 20%);
    h1 {
      padding-top: 130px;
    }
  }
  .profile {
    @extend .container;
    text-align: center;
    padding: 40px 0;
  }
  #login {
    margin-top: 40px;
  }
}
.node-type-webform {
  article {
    @extend .container;
    margin-top: 30px;
  }
}
.os-animation {
  opacity: 0;
  &.animated {
    opacity: 1;
    @include transition(all .2s);
  }
}
.site-map-menus {
  @extend .container;
  padding: 50px 0;
}
ul.pager {
  display: flex;
  justify-content: center;
  padding: 30px 0;
  li {
    display: inline-block;
    padding: 10px;
    background: map_get($theme-colors, light);
    color: #000;
    margin: 0 0 0 5px;
    a {
      text-decoration: none;
      &:hover {
        color: map_get($theme-colors, dark);
      }
    }
    &.pager-current {
      font-weight: 700;
    }
  }
}