.node-type-contact {
	.content-banner {
		height: 500px;
		h1 {
			padding-top: 170px;
		}
	}
	.contact-text {
		padding: 40px 0 0 0;
	}
	.shops {
		margin-top: -180px;
		display: flex;
		justify-content: space-between;
		@include media-breakpoint-down(sm) {
			flex-wrap: wrap;
		}
		.shop {
			&.half {
				position: relative;
				height: 330px;
				width: 49%;
				@include media-breakpoint-down(sm) {
					width: 100%;
					margin-bottom: 10px;
				}
				.shop-img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					z-index: 1;
				}
				.shop-text {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					padding: 50px 30px;
					background: rgba(83,103,171,0);
					color: #fff;
					z-index: 2;
					@include transition(all .3s);
					@include media-breakpoint-down(md) {
						background: rgba(83,103,171,.8);
					}
					h2 {
						color: #fff;
						padding-top: 200px;
						padding-bottom: 10px;
						@include transition(all .3s);
						@include media-breakpoint-down(md) {
							padding-top: 0;
						}
					}
					.shop-text-content {
						color: #fff;
						opacity: 0;
						@include transition(all .3s);
						@include media-breakpoint-down(md) {
							opacity: 1;
						}
					}
					p {
						color: #fff;
						margin-bottom: 5px;
					}
					a {
						color: #fff;
					}
				}
				&:hover {
					.shop-text {
						background: rgba(83,103,171,.9);
						@include transition(all .3s);
						h2 {
							padding-top: 0;
							@include transition(all .3s);
						}
						.shop-text-content {
							opacity: 1;
							@include transition(all .3s);
						}
					}
				}
			}
			&.single {
				display: flex;
				justify-content: space-between;
				width: 100%;
				flex-wrap: wrap;
				.shop-img {
					height: 330px;
					width: 49%;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					@include media-breakpoint-down(sm) {
						width: 100%;
					}
				}
				.shop-text {
					width: 49%;
					color: #fff;
					background: rgba(83,103,171,.7);
					padding: 30px;
					@include media-breakpoint-down(sm) {
						width: 100%;
					}
					p {
						color: #fff;
					}
					h2 {
						color: #fff;
						padding-bottom: 20px;
					}
					.shop-text-content {
						color: #fff;
					}
					a {
						color: #fff;
					}
				}
			}
		}
	}
	.contact-form {
		margin: 80px 0;
		@include media-breakpoint-down(sm) {
			margin: 20px 0;
		}
		h2 {
			font-size: 1.5rem;
			text-align: center;
			color: map_get($theme-colors, purple);
			margin-bottom: 30px;
		}
		.form-block {
			padding: 80px;
			border: 10px solid map_get($theme-colors, light);
			@include media-breakpoint-down(sm) {
				padding: 20px;
			}
			form {
				padding-bottom: 20px;
			}
			> div {
				@extend .row;
			}
			label {
				font-size: .9rem;
				font-weight: bold;
				color: map_get($theme-colors, purple);
			}
			input[type="text"], input[type="email"] {
				border: 1px solid darken(map_get($theme-colors, light), 10%);
			}
		}
	}
	.contact-text {
		a {
			color: map_get($theme-colors, purple);
		}
		p, ul li {
			color: map_get($theme-colors, middle);
		}
		p.evidence {
			color: #fff;
			a {
				color: #fff;
			}
		}
	}
}