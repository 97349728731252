.node-type-product {
  .content-banner {
    height: 130px;
  }
  .product-top {
    background-color: map-get($theme-colors, lightpurple);
    padding: 15px 0;
    .back {
      margin-bottom: 0;
      &:before {
        content: "\f060";
        margin-right: 10px;
        font-family: $font-awesome;
        font-weight: 700;
        color: #fff;
      }
      a {
        color: #fff;
        text-decoration: none;
        border-bottom: 1px solid #fff;
        @include transition(all 0.2s);
        &:hover {
          border: 0;
          @include transition(all 0.2s);
        }
      }
    }
  }
  .product-full {
    .container {
      display: flex;
      justify-content: space-between;
      padding-top: 50px;
      position: relative;
      @include media-breakpoint-down(lg) {
        flex-wrap: wrap;
      }
      @include media-breakpoint-down(md) {
        padding-top: 10px;
      }
    }
    .product-images {
      width: 480px;
      @include media-breakpoint-down(lg) {
        width: 300px;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 20px;
      }
      .product-big {
        height: 480px;
        margin-bottom: 10px;
        @include media-breakpoint-down(lg) {
          height: 300px;
        }
        @include media-breakpoint-down(md) {
          text-align: center;
        }
        img {
          max-width: 100%;
          max-height: 480px;
          @include media-breakpoint-down(md) {
            max-height: 300px;
          }
          &.hidden {
            display: none;
          }
        }
        iframe {
          width: 100%;
          height: 480px;
          @include media-breakpoint-down(lg) {
            height: 300px;
          }
          &.hidden {
            display: none;
          }
        }
      }
      .product-thumbs {
        display: flex;
        flex-wrap: wrap;
        > div {
          width: 58px;
          height: 58px;
          border: 1px solid lighten(map-get($theme-colors, middle), 20%);
          margin-right: 2%;
          margin-bottom: 10px;
          background-repeat: no-repeat;
          background-size: cover;
          box-shadow: inset 0px 0px 0px 3px rgba(255, 255, 255, 1);
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          &:hover,
          &.active {
            border-color: map-get($theme-colors, primary);
          }
          &.video {
            background-color: map-get($theme-colors, light);
            display: flex;
            justify-content: center;
            align-items: center;
            i {
              color: map-get($theme-colors, middle);
            }
          }
        }
      }
    }
    .product-infos {
      width: calc(100% - 530px);
      @include media-breakpoint-down(lg) {
        width: calc(100% - 340px);
      }
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      .promo {
        display: flex;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background-color: map-get($theme-colors, primary);
        color: #fff;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 20px;
        left: 460px;
        @include media-breakpoint-down(md) {
          position: static;
        }
      }
      h1 {
        color: map-get($theme-colors, primary);
        font-size: 2rem;
        font-weight: 600;
      }
      .info-text {
        p {
          color: lighten(map-get($theme-colors, middle), 10%);
        }
      }
      .product-form {
        display: none;
      }
      form {
        .form-radios {
          display: flex;
          flex-wrap: wrap;
        }
        .form-type-radio {
          input {
            display: none;
          }
          label {
            padding-left: 0;
            display: inline-block;
            margin-right: 5px;
            .var-item {
              width: 50px;
              height: 50px;
              background-size: cover;
              background-position: center;
              border: 1px solid map-get($theme-colors, middle);
              box-shadow: inset 0px 0px 0px 3px rgba(255, 255, 255, 1);
              margin-right: 5px;
            }
            &.active {
              .var-item {
                border-color: map-get($theme-colors, primary);
              }
            }
          }
        }
        .product-item-price {
          display: flex;
          flex-wrap: wrap;
          .label {
            display: block;
            font-size: 1rem;
            color: lighten(map-get($theme-colors, middle), 10%);
            font-weight: 600;
            margin-bottom: 10px;
            width: 100%;
            order: 1;
          }
          s {
            font-size: 1.1rem;
            color: lighten(map-get($theme-colors, middle), 10%);
            margin-left: 5px;
            position: relative;
            top: -15px;
            order: 3;
          }
          .price-number {
            font-size: 2.5rem;
            font-weight: 600;
            order: 2;
            color: map-get($theme-colors, primary);
          }
        }
        .product-add {
          display: flex;
          justify-content: space-between;
          margin: 40px 0 20px;
          @include media-breakpoint-down(md) {
            flex-wrap: wrap;
          }
        }
        .product-qty {
          width: 50%;
          display: flex;
          flex-wrap: wrap;
          @include media-breakpoint-down(md) {
            width: 100%;
            margin-bottom: 20px;
          }
          h2 {
            width: 100%;
            font-size: 1rem;
          }
          a.min,
          a.plus {
            color: map-get($theme-colors, dark);
            font-size: 0.7rem;
            align-self: flex-start;
            padding: 0 10px;
            border-radius: 100%;
            padding: 4px 10px;
            background-color: map-get($theme-colors, light);
          }
          .form-item {
            margin: 0;
          }
          .form-text:disabled {
            background-color: transparent;
            border: 0;
            height: auto;
            width: 50px;
            padding-top: 5px;
            text-align: center;
          }
        }
        input[type="submit"] {
          border-radius: 0;
          font-size: 1rem;
          padding-right: 60px;
          background-image: url(../images/bag.png);
          background-position: top 10px right 30px;
          background-repeat: no-repeat;
        }
      }
      .continue {
        text-align: right;
        a {
          color: map-get($theme-colors, dark);
        }
      }
      .product-shipping {
        padding: 20px 0;
        position: relative;
        border-bottom: 1px solid map-get($theme-colors, light);
        margin-bottom: 20px;
        &:before {
          content: "";
          display: inline-block;
          width: 35px;
          height: 30px;
          position: relative;
          top: 13px;
          margin-right: 10px;
          background-image: url(../images/truck.png);
          background-repeat: no-repeat;
        }
        em {
          display: inline-block;
          color: lighten(map-get($theme-colors, middle), 10%);
        }
      }
      .product-desc {
        color: lighten(map-get($theme-colors, middle), 10%);
        p {
          color: lighten(map-get($theme-colors, middle), 10%);
        }
      }
    }
  }
}
.product-other {
  margin: 40px 0 0 0;
  padding: 40px 0;
  background-color: map-get($theme-colors, light);
  h3 {
    font-weight: 700;
    margin-bottom: 30px;
  }
  .swiper-container {
    padding-bottom: 50px;
    overflow: visible;
    .swiper-wrapper {
      align-items: stretch;
    }
    .product {
      border: 1px solid darken(map-get($theme-colors, light), 10%);
      margin-bottom: 40px;
      position: relative;
      background-color: #fff;
      height: auto;
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
      a {
        display: flex;
        padding: 20px;
        text-decoration: none;
        @include media-breakpoint-down(md) {
          flex-wrap: wrap;
        }

        .product-pic {
          width: 50%;
          text-align: center;
          @include media-breakpoint-down(md) {
            width: 100%;
            margin-bottom: 10px;
          }
          img {
            max-width: 100%;
            max-height: 250px;
          }
        }
        .product-content {
          width: 50%;
          padding-left: 40px;
          @include media-breakpoint-down(md) {
            width: 100%;
          }
          .promo {
            display: flex;
            width: 50px;
            height: 50px;
            border-radius: 100%;
            background-color: map-get($theme-colors, primary);
            color: #fff;
            font-weight: 600;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 2px;
            left: calc(50% - 25px);
          }
        }
        h2 {
          font-family: $font-family-sans-serif;
          font-size: 1.1rem;
          font-weight: 700;
          color: map-get($theme-colors, primary);
        }
        .product-item-price {
          display: flex;
          flex-wrap: wrap;
          .label {
            display: block;
            font-size: 1rem;
            color: map-get($theme-colors, middle);
            font-weight: 600;
            margin-bottom: 5px;
            width: 100%;
            order: 1;
          }
          s {
            font-size: 1.1rem;
            color: map-get($theme-colors, middle);
            margin-left: 5px;
            position: relative;
            top: -5px;
            order: 3;
          }
          .price-number {
            font-size: 2rem;
            font-weight: 600;
            order: 2;
          }
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
    .swiper-pagination-bullet-active {
      background-color: map-get($theme-colors, primary);
    }
  }
}
