.page-panier-commande {
  .form-type-radios {
    > label {
      font-size: 1rem;
      font-weight: 700;
      color: #fff;
    }
    .form-radios {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 20px 0;
      > div {
        width: 48%;
        padding-bottom: 20px;
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
        label {
          color: #fff;
          font-size: 0.9rem;
        }
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
  .personnal {
    label {
      font-size: 0.9rem;
      color: #fff;
      font-weight: 700;
    }
  }
  .addresses {
    fieldset {
      legend {
        font-size: 1rem;
        color: #fff;
        font-weight: 700;
      }
      .fieldset-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .form-item {
          width: 48%;
          &.form-type-checkbox {
            width: 100%;
          }
        }
        label {
          font-size: 0.9rem;
          color: #fff;
          font-weight: 700;
        }
      }
    }
    .form-type-checkbox {
      label {
        color: #fff;
        font-size: 0.9rem;
      }
    }
  }
  .mentions {
    p {
      font-size: 0.8rem;
      color: #fff;
    }
  }
  .form-type-textarea {
    label {
      font-size: 0.9rem;
      color: #fff;
      font-weight: 700;
    }
  }
  .form-action {
    text-align: right;
  }
  .form-select.error,
  .form-text.error,
  .form-textarea.error {
    border: 1px solid #a00;
  }
}
.page-panier-commande-confirmation {
  #edit-order-resume {
    legend {
      font-size: 1.4rem;
      color: #fff;
      font-weight: 700;
    }
    h2 {
      color: #fff;
      font-size: 1.2rem;
    }
  }
  .resume-info {
    color: #fff;
    p {
      color: #fff;
    }
    h2 {
      color: #fff;
      font-size: 1.2rem;
    }
  }
  .address-info,
  #edit-order-customer-billing {
    color: #fff;
    p {
      color: #fff;
    }
    h2 {
      color: #fff;
      font-size: 1.2rem;
    }
  }
  .payment-info {
    color: #fff;
    p {
      color: #fff;
    }
    h2 {
      color: #fff;
      font-size: 1.2rem;
    }
    img {
      max-width: 100%;
    }
  }
  .legal-info {
    label {
      font-size: 0.9rem;
      a {
        color: #fff;
      }
    }
    .form-type-checkbox {
      input[type="checkbox"] {
        width: 20px;
        height: 20px;
      }
      label {
        padding-left: 30px;
      }
    }
  }
  .paiement-action {
    display: flex;
    justify-content: flex-end;
    margin: 40px 0;
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
    .form-accept {
      color: #fff;
      margin-right: 20px;
    }
  }
}
.page-commande-confirmation {
  .confirm {
    margin: 40px 0;
    font-weight: 700;
    font-size: 1rem;
  }
}
