// -------------------------------------------------------------
//   Paragraphs item texte
// -------------------------------------------------------------

.inner-content-texte {
	padding: 15px 150px;

	@include media-breakpoint-down(lg) {
		padding-left: 30px;
		padding-right: 30px;
	}

	@include media-breakpoint-down(sm) {
		padding: 10px 15px;
	}
	img {
		max-width: 100%;
    	height: auto !important;
	}
}