.promotions-intro {
  padding: 50px 0;
  text-align: center;
  a {
    color: map_get($theme-colors, middle);
  }
  ul {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 30px 0;
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
    li {
      width: 33%;
      border: 1px solid map_get($theme-colors, light);
      padding: 20px 50px;
      margin: 0;
      list-style: none;
      @include media-breakpoint-down(lg) {
        padding: 20px;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      img {
        height: 50px;
      }
      span {
        display: block;
        margin: 10px auto;
        width: 180px;
      }
    }
  }
}
.promotions-content {
  background-color: map_get($theme-colors, light);
  text-align: center;
  padding: 50px 0;
  h2 {
    margin-bottom: 50px;
  }
  .promotions-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > div {
      width: 48%;
      background-color: #fff;
      margin-bottom: 20px;
      @include transition(all 0.2s);
      @include media-breakpoint-down(lg) {
        width: 33%;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      &:hover {
        -webkit-box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.12);
        @include transition(all 0.2s);
      }
    }
    .promotion {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      .promo-pic {
        width: 50%;
        height: 180px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        &.no-pic {
          background-size: 100px;
        }
      }
      .promo-text {
        width: 50%;
        text-align: left;
        padding: 20px;
      }
      h3 {
        font-size: 1.3rem;
        margin-bottom: 10px;
      }
      .btn-secondary {
        font-size: 0.8rem;
        font-weight: 500;
        padding: 3px 20px;
      }
    }
  }
}
.page-papeterie-promotions {
  .content-banner {
    height: 320px;
    h1 {
      padding: 190px 0 30px;
    }
    .breadcrumb {
      display: none;
    }
  }
}
