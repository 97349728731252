.ecoles-intro {
  padding: 50px 0;
  text-align: center;
  a {
    color: map_get($theme-colors, middle);
  }
  ul {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 30px 0;
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
    li {
      width: 33%;
      border: 1px solid darken(map_get($theme-colors, light), 10%);
      padding: 20px 50px;
      margin: 0 -1px 0 0;
      list-style: none;
      @include media-breakpoint-down(lg) {
        padding: 20px;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      img {
        height: 50px;
      }
      span {
        display: block;
        margin: 10px auto;
        width: 180px;
      }
    }
  }
}
.ecoles-content {
  background-color: map_get($theme-colors, light);
  text-align: center;
  padding: 50px 0;
  .ecoles-list {
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 24%;
      background-color: #fff;
      margin: 20px 0.5%;
      @include transition(all 0.2s);
      @include media-breakpoint-down(lg) {
        width: 33%;
        margin: 20px 0.1%;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
        margin: 20px 0;
      }
      &:hover {
        -webkit-box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.12);
        @include transition(all 0.2s);
      }
    }
    .ecole {
      padding: 30px;
      .pic {
        min-height: 110px;
        margin-bottom: 20px;
      }
      img {
      }
      h3 {
        font-size: 1.3rem;
        margin-bottom: 5px;
      }
      .btn-secondary {
        font-size: 0.8rem;
        font-weight: 500;
        padding: 3px 20px;
      }
    }
  }
}
.page-papeterie-ecoles {
  .content-banner {
    height: 320px;
    @include media-breakpoint-down(md) {
      height: 400px;
    }
    h1 {
      padding: 190px 0 30px;
      @include media-breakpoint-down(md) {
        padding-top: 160px;
      }
    }
    .breadcrumb {
      display: none;
    }
  }
}
