// Variables
//

//
// Color system
//

$theme-colors: (
  primary: #e6007e,
  secondary: #878585,
  lightpink: #f4a49f,
  lightblue: #b5d7d8,
  purple: #6f80b8,
  lightpurple: #94a0c8,
  darkblue: #293e83,
  success: #28a745,
  info: #17a2b8,
  warning: #ffc107,
  danger: #dc3545,
  light: #f8f8f8,
  middle: #898586,
  dark: #524f4f,
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
) !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "Montserrat", sans-serif;
$font-family-fancy: "appelberry", sans-serif;
$font-family-fancy2: "gloriahallelujah", sans-serif;
$font-awesome: "Font Awesome 5 Free";
$font-awesome-brands: "Font Awesome 5 Brands";
