.subsite-copies-impressions {
  #banner {
    height: 800px;
    .banner-inner {
      @extend .container;
      text-align: center;
      padding-top: 230px;
      @include media-breakpoint-down(sm) {
        padding-top: 160px;
      }
    }
    h1 {
      color: #fff;
      font-weight: 600;
      font-size: 2.5rem;
      span {
        font-weight: 600;
        font-size: 1.5rem;
        display: block;
        margin-top: 10px;
        margin-bottom: 40px;
      }
    }
    &.no-products {
      height: 550px;
    }
  }
  .main-header {
    #nav {
      > ul {
        > li {
          > a,
          > .nolink {
            color: #fff;
          }
          &.highlight {
            a {
              color: map_get($theme-colors, primary);
            }
          }
        }
      }
    }
    a.logo {
      background-image: url(../images/logo-white.svg);
      width: 220px;
      height: 67px;
      img {
        display: none;
      }
    }
    &.scrolled {
      a.logo {
        background-image: none;
        img {
          display: block;
        }
      }
      #nav {
        > ul {
          > li {
            > a,
            > .nolink {
              color: map_get($theme-colors, middle);
            }
            &.highlight {
              > a {
                color: map_get($theme-colors, primary);
              }
            }
          }
        }
      }
    }
  }
}
