// -------------------------------------------------------------
//   Main footer
//   Footer styles
// -------------------------------------------------------------

#footer {
	margin: 100px 0 0 0;
	overflow-x: hidden;
	@include media-breakpoint-down(sm) {
	    margin-top: 20px;
	  }
	.col-md-11 {
		background-color: map_get($theme-colors, light);
		position: relative;
		padding: 50px 10px;
		&:after {
			content: '';
			display: block;
			width: 9999px;
			height: 100%;
			position: absolute;
			top: 0;
			right: 100%;
			background-color: map_get($theme-colors, light);
		}
	}
	a.logo {
		max-width: 250px;	
		display: block;
		@include media-breakpoint-down(sm) {
			margin-bottom: 20px;
		}
		img {
			max-width: 100%;
		}
	}
	.activites {
		display: flex;
		font-size: .8rem;
		ul {
			padding: 0;
			margin: 0 0 20px 0;
			li {
				list-style: none;
				display: inline-block;
				margin-left: 10px;
				a {
					color: map_get($theme-colors, middle);
					font-weight: 600;
					font-size: .7rem;
					text-decoration: none;
				}
			}
		}
		p {
			font-size: .8rem;
		}
	}
	.fb {
		text-align: center;
		a {
			color: map_get($theme-colors, middle);
			display: inline-block;
			padding: 3px 12px;
			border-radius: 100%;
			border: 1px solid map_get($theme-colors, middle);
			font-size: .8rem;
			margin-top: 50px;
			@include media-breakpoint-down(sm) {
				margin: 20px 0;
			}
		}
	}
}
.copy-footer {
	background: map_get($theme-colors, middle);
	padding: 30px 0;
	color: #fff;
	font-size: .8rem;
	a, p {
		color: #fff;
		font-size: .8rem;
	}
}