.node-type-promotion-manga {
    .content-banner {
        background-image: url(../images/banner-manga.png);
        background-repeat: no-repeat;
        background-position: top left;
        background-size: auto 100%;
        background-color: #85bae6;
        height: 700px;
        position: relative;
        margin-bottom: 50px;
        &:before {
            display: none;
        }
        &:after {
            content: "";
            display: block;
            width: 484px;
            height: 453px;
            background-image: url(../images/banner-manga-perso.png);
            background-repeat: no-repeat;
            position: absolute;
            bottom: -40px;
            left: 230px;
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
        .breadcrumb {
            display: none;
        }
        h1 {
            padding-left: 50%;
            padding-right: 50px;
            font-family: "anime";
            text-align: left;
            font-weight: 400;
            font-size: 2.4rem;
            font-style: italic;
            line-height: 1.4;
            @include media-breakpoint-down(lg) {
                padding-left: 0;
            }
            @include media-breakpoint-down(sm) {
                font-size: 1.5rem;
                padding-top: 200px;
            }
        }
    }
    .banner-links {
        .links {
            position: relative;
            width: 50%;
            left: 50%;
            top: -250px;
            @include media-breakpoint-down(lg) {
                top: -300px;
                left: 0;
                width: 100%;
            }
            .product-link {
                color: #fff;
                font-weight: 700;
            }
        }
    }
    .intro {
        position: relative;
        margin-top: -20px;
        min-height: 320px;
        @include media-breakpoint-down(sm) {
            margin-top: -100px;
        }
        .manga-pic {
            position: absolute;
            left: 50%;
            width: 720px;
            height: 389px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            top: -200px;
            @include media-breakpoint-down(sm) {
                position: static;
                width: 100%;
                height: 250px;
            }
        }
        p {
            padding-right: 80px;
            padding-left: 30px;
            @include media-breakpoint-down(lg) {
                padding: 20px;
            }
        }
        a.read-link {
            font-family: "anime";
            font-size: 1.5rem;
            font-style: italic;
            display: inline-block;
            margin-top: 30px;
            @include media-breakpoint-down(sm) {
                margin-top: 0;
            }
        }
    }
    .products-selection {
        position: relative;
        &:before {
            content: "";
            display: block;
            width: 544px;
            height: 562px;
            background-image: url(../images/illu-products.png);
            background-repeat: no-repeat;
            position: absolute;
            top: -100px;
            left: -50px;
            z-index: -1;
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        h2 {
            text-align: center;
            font-family: "anime";
            font-size: 1.3rem;
            font-style: italic;
            color: #000;
            width: 50%;
            display: block;
            margin: 0 auto 50px auto;
            position: relative;
            z-index: 2;
            strong {
                color: map_get($theme-colors, primary);
            }
        }
        #produits {
            position: relative;
            z-index: 2;
            /* clear fix */
            .grid:after {
                content: "";
                display: block;
                clear: both;
            }
            .grid-sizer,
            .grid-image-item {
                width: 33.333%;
                //height: 406px;
                margin-bottom: 10px;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
            .gutter-sizer {
                width: 0;
            }
            .grid-image-item {
                float: left;
                &.grid-width2 {
                    width: 50%;
                }
                &.grid-height2 {
                    height: 812px;
                }
            }
            .grid-image-item img {
                display: block;
                max-width: 100%;
            }
        }
    }
    #other {
        padding: 20px 0;
        h3 {
            color: #000;
            text-align: center;
            font-family: "anime";
            font-size: 1.3rem;
            margin: 20px 0 40px;
        }
        .swiper-other-promo {
            padding-left: 50px;
            padding-right: 50px;
            @include media-breakpoint-down(sm) {
                padding: 0;
            }
        }
        .swiper-slide {
            text-align: center;
        }
        .swiper-button-next {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
        }
        .swiper-button-prev {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
        }
        .swiper-button-disabled {
            opacity: 0;
        }
        .promotion-img {
            height: 310px;
            display: block;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
        .promotion-text {
            font-family: "anime";
            font-size: 1rem;
            text-decoration: none;
            margin: 20px 0 0 0;
            display: inline-block;
        }
    }
}