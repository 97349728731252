// -------------------------------------------------------------
//   Types
// -------------------------------------------------------------

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
	margin-bottom: (1rem / 2);
	color: inherit;
	line-height: 1.1;
	font-family: inherit;
	font-weight: 500;
}
h1,.h1{
	font-size: 2.5rem;
}
h2,.h2 {
	font-size: 1.75rem; 
	color: map_get($theme-colors, middle);
	@include media-breakpoint-down(sm) {
		font-size: 1.2rem;
	}
}
h3,.h3 {
	font-size: 1.5rem;
	color: map_get($theme-colors, primary);
	@include media-breakpoint-down(sm) {
		font-size: 1.1rem;
	}
}
h4,.h4 {
	font-size: 1.3rem;
}
h5,.h5 {
	font-size: 1.2rem; 
}
h6,.h6 {
	font-size: 1rem;
	font-weight: 700;
}
ul, ol {
	li {
		margin: 0 0 0 20px;
    	color: map_get($theme-colors, dark);
    	font-size: .9rem;
	}
}
p {
  color: map_get($theme-colors, dark);
  font-size: .9rem;
}
a {
	color: map-get($theme-colors, primary);
	text-decoration: underline;
	&:hover {
		color: map-get($theme-colors, primary);
		text-decoration: none;
	}
}

.evidence {
  margin: 20px 0;
  padding: 20px 0;
  background: map-get($theme-colors, primary);
  color: #fff;
  text-align: center;
  font-weight: bold;
  a {
  	color: #fff;
  	@include media-breakpoint-down(sm) {
    	margin: 10px 20px;
  	}
  }
}

.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
blockquote {
	padding: 20px 20px;
	display: inline-block;
	position: relative;
	margin: 20px 0 0 0;
	&:before {
		content: '“';
		font-size: 2rem;
		color: map-get($theme-colors, dark);
		position: absolute;
		top: 0;
		left: 0;
	}
	&:after {
		content: '”';
		font-size: 2rem;
		color: map-get($theme-colors, dark);
		margin-left: 10px;
		position: absolute;
		bottom: 0;
		right: 0;
	}
}