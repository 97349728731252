.page-imprimerie-realisations {
	section.view-realisations {
		@extend .container;
		padding: 40px 0; 
		.views-exposed-widget {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: -150px;
			margin-bottom: 130px;
			@include media-breakpoint-down(lg) {
				margin-top: -130px;
				margin-bottom: 100px;
			}
			label {
				margin-right: 10px;
				font-size: .9rem;
				color: #fff;
			}
			.form-item {
				margin: 0;
				select {
					font-size: .9rem;
					font-weight: 700;
					border-radius: 5px;
					height: 40px;
				}
			}
		}
		.view-content {
			a {
				text-decoration: none;
				@include media-breakpoint-down(sm) {
					display: block;
					padding: 10px;
				}
				&:hover {
					figure {
						top: -10px;
						@include transition(all .2s);
					}
				}
			}
			figure {
				height: 260px;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				position: relative;
				top: 0;
				@include transition(all .2s);
			}
			h2 {
				font-family: $font-family-sans-serif;
				font-size: 1rem;
				font-weight: 700;
			}
			p {
				font-family: $font-family-sans-serif;	
			}
		}
	}
	h1 {
		@include media-breakpoint-down(sm) {
			padding-top: 150px;
		}
	}
} 
.node-type-realisation {
	.content-banner {
		height: 400px;
		background: map_get($theme-colors, purple);
		h1 {
			padding-top: 190px;
		}
	}
	.main-header {
		#nav {
			> ul {
				> li {
					> a, > .nolink {
						color: #fff;
					}
					&.highlight {
						a {
							color: map_get($theme-colors, primary);
						}
					}
				}
			}
		}
		a.logo {
			background-image: url(../images/logo-white.svg);
			width: 220px;
			height: 67px;
			img {
				display: none;
			}
		}
		&.scrolled {
			a.logo {
				background-image: none;
				img {
					display: block;
				}
			}
			#nav {
				> ul {
					> li {
						> a, > .nolink {
							color: map_get($theme-colors, middle);
						}
						&.highlight {
							> a {
								color: map_get($theme-colors, primary);
							}
						}
					}
				}
			}
		}
	}
	article {
		@extend .container;
		padding-top: 30px;
		.back {
			a {
				text-decoration: none;
				&:before {
					content:'\f0d9';
					font-family: $font-awesome;
					font-weight: 600;
					margin-right: 5px;
				}
			}
		}
	}
	.grid {
		-moz-column-count: 2;
		-webkit-column-count: 2;
		column-count: 2;
		-moz-column-gap: 30px;
		-webkit-column-gap: 30px;
		column-gap: 30px;
		width: 70%;
		margin: 50px auto;
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
		.grid-item {
			padding-bottom: 30px;
			-webkit-column-break-inside: avoid;
			page-break-inside: avoid;
			break-inside: avoid;
			img {
				max-width: 100%;
			}
		}
	}
}