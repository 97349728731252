// -------------------------------------------------------------
//   Paragraphs item cta
// -------------------------------------------------------------

.content-cta {
	margin: 15px 0;
	.inner-content-cta {
		padding: 50px;
		background: #86c4cd;
		background: -moz-linear-gradient(left, #86c4cd 18%, #86c4cd 18%, #a9b3d6 100%);
		background: -webkit-linear-gradient(left, #86c4cd 18%,#86c4cd 18%,#a9b3d6 100%);
		background: linear-gradient(to right, #86c4cd 18%,#86c4cd 18%,#a9b3d6 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#86c4cd', endColorstr='#a9b3d6',GradientType=1 );
		@include media-breakpoint-down(sm) {
			padding: 20px;
		}
		h2 {
			font-size: 1.3rem;
			color: #fff;
			line-height: 2rem;
		}
		.cta-link {
			text-align: center;
		}
		a {
			@extend .btn;
			@extend .btn1;
		}
	}
}