.subsite-papeterie {
  .content-banner {
    h1 {
      font-family: $font-family-fancy;
      font-weight: 400;
    }
  }
  #banner {
    height: 1080px;
    overflow-x: hidden;
    background-color: #85bae6;
    @include media-breakpoint-down(md) {
      background-image: none !important;
    }
    .container {
      position: relative;
    }
    .banner-inner {
      position: relative;
      z-index: 10;
      @extend .container;
      text-align: center;
      padding-top: 330px;
    }
    h1 {
      color: #fff;
      font-weight: 400;
      font-size: 3.8rem;
      font-family: $font-family-fancy;
      text-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
      span {
        font-weight: 600;
        font-size: 1.5rem;
        display: block;
        margin-top: 10px;
        margin-bottom: 40px;
        font-family: $font-family-fancy2;
      }
    }
    p {
      a {
        &.btn {
          background-color: #fff;
          color: map_get($theme-colors, darkblue);
          border: 0;
          font-weight: 700;
          &:hover {
            background-color: map_get($theme-colors, primary);
            color: #fff;
          }
        }
      }
    }
    .cahier {
      width: 463px;
      height: 466px;
      position: absolute;
      top: 240px;
      left: -80px;
      z-index: 5;
      background-image: url(../images/cahier.png);
      background-repeat: no-repeat;
      background-position: top left;
    }
    .stabilo {
      width: 126px;
      height: 114px;
      position: absolute;
      top: 360px;
      left: 180px;
      z-index: 15;
      background-image: url(../images/stabilo.png);
      background-repeat: no-repeat;
      background-position: top left;
    }
    .taille {
      width: 323px;
      height: 171px;
      position: absolute;
      top: 630px;
      left: 180px;
      z-index: 10;
      background-image: url(../images/taille.png);
      background-repeat: no-repeat;
      background-position: top left;
    }
    .cartable {
      width: 541px;
      height: 627px;
      position: absolute;
      top: 350px;
      left: 690px;
      z-index: 15;
      background-image: url(../images/cartable.png);
      background-repeat: no-repeat;
      background-position: top left;
    }
    .crayons {
      width: 459px;
      height: 267px;
      position: absolute;
      top: 170px;
      right: -210px;
      z-index: 10;
      background-image: url(../images/crayons.png);
      background-repeat: no-repeat;
      background-position: top left;
    }
  }
  .main-header {
    #nav {
      > ul {
        > li {
          > a,
          > .nolink {
            color: #fff;
          }
          &.highlight {
            a {
              color: map_get($theme-colors, primary);
            }
          }
        }
      }
    }
    a.logo {
      background-image: url(../images/logo-white.svg);
      width: 220px;
      height: 67px;
      img {
        display: none;
      }
    }
    &.scrolled {
      a.logo {
        background-image: none;
        img {
          display: block;
        }
      }
      #nav {
        > ul {
          > li {
            > a,
            > .nolink {
              color: map_get($theme-colors, middle);
            }
          }
        }
      }
    }
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-family-fancy;
  }
  #info {
    p {
      font-family: $font-family-fancy;
      font-weight: 400;
      color: map_get($theme-colors, dark);
    }
    a.btn1 {
      background-color: map_get($theme-colors, primary);
    }
  }
}
