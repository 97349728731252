// -------------------------------------------------------------
//   Paragraphs item galerie
// -------------------------------------------------------------

.content-galerie {
  padding: 15px 20px;
  .swiper-slide {
    height: 500px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .swiper-button-prev, .swiper-button-next {
    color: #fff;
    font-size: 1.3rem;
    background-image: none;
    background: rgba(255,255,255,.5);
    padding: 10px;
    width: auto;
    height: auto;
    &.swiper-button-disabled {
      display: none;
    }
  }
}
