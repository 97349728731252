@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700");

@font-face {
  font-family: "appelberry";
  src: url("../webfonts/appleberry-webfont.woff2") format("woff2"),
    url("../webfonts/appleberry-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "gloriahallelujah";
  src: url("../webfonts/gloriahallelujah-webfont.woff2") format("woff2"),
    url("../webfonts/gloriahallelujah-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "anime";
  src: url("../webfonts/AnimeAce2.0BB.woff2") format("woff2"),
    url("../webfonts/AnimeAce2.0BB.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
