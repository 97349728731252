// -------------------------------------------------------------
//   Banner
// -------------------------------------------------------------
.node-type-portal {
  .main-header {
    &.scrolled {
      .topbar {
        @include media-breakpoint-down(sm) {
          margin-top: -60px;
        }
      }
    }
  }
  #banner {
    height: 950px;
    overflow-y: hidden;
    @include media-breakpoint-down(md) {
      height: 700px;
    }
    @include media-breakpoint-down(sm) {
      height: 600px;
    }
    .container {
      position: relative;
      height: 100%;
      .draws {
        position: absolute;
        top: 150px;
        left: -200px;
        width: 1360px;
        height: 967px;
        background-image: url(../images/portal-deco.png); 
        background-repeat: no-repeat;
        background-position: top left;
        @include media-breakpoint-down(md) {
          left: -700px;
        }
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
      .book {
        width: 217px;
        height: 199px; 
        position: absolute;
        top: 520px;
        left: 20px;
        background-image: url(../images/book-deco.png);
        background-repeat: no-repeat;
        background-position: top left;
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
      .plane {
        width: 267px; 
        height: 128px;
        position: absolute;
        top: 440px;
        left: 370px;
        background-image: url(../images/plane-deco.png);
        background-repeat: no-repeat;
        background-position: top left;
        z-index: 2;
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
      h1 {
        margin-top: 250px;
        color: map_get($theme-colors, primary);
        font-weight: 400;
        font-size: 2.5rem;
        margin-right: 100px;
        font-family: $font-family-fancy;
        @include media-breakpoint-down(lg) {
          margin-right: 0;
          margin-left: 100px;
        }
        @include media-breakpoint-down(sm) {
          margin: 170px 0 0 0;
          text-align: center;
          font-size: 2rem;
        }
        span {
          color: map_get($theme-colors, dark);
          display: block;
        }
      }
      p {
        margin-top: 100px;
        @include media-breakpoint-down(lg) {
          margin-left: 100px;
          margin-top: 50px;
        }
        @include media-breakpoint-down(sm) {
          margin: 50px 0 0 0;
          text-align: center;
        }
      }
      a.btn.btn2 {
        border: 0;
        font-weight: 700;
      }
    }
  }
}
#banner {
  height: 500px;
  margin-top: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }
}
.content-banner {
  height: 520px;
  margin-top: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  background-color: map_get($theme-colors, purple);
  @include media-breakpoint-down(sm) {
    margin-top: 0;
  } 
  h1 {
    padding: 250px 0 30px 0;
    font-weight: 600;
    color: #fff;
    text-shadow: 0px 6px 9px rgba(0, 0, 0, .5);
  }
  .breadcrumb {
    font-size: .8rem;
    color: #fff;
    a {
      color: #fff;
    }
  }
}
.subsite-imprimerie {
  .content-banner {
    background-image: url(../images/banner-imprimerie.jpg);
  }
}
.subsite-copies-impressions {
  .content-banner {
    background-image: url(../images/banner-copie.png);
  }
}
.subsite-papeterie {
  .content-banner {
    background-image: url(../images/banner-papeterie.jpg);
  }
}
.subsite-librairie {
  .content-banner {
    background-image: url(../images/banner-librairie.jpg);
  }
}