.subsite-imprimerie {
	#banner {
		height: 900px;
		.banner-inner {
			@extend .container;
			text-align: center;
			padding-top: 330px;
		}
		h1 {
			color: #fff;
			font-weight: 600;
			font-size: 2.5rem;
			span {
				font-weight: 600;
				font-size: 1.5rem;
				display: block;
				margin-top: 10px;
				margin-bottom: 40px;
			}
		}
	}
	.main-header {
		#nav {
			> ul {
				> li {
					> a, > .nolink {
						color: #fff;
					}
					&.highlight {
						> a {
							color: map_get($theme-colors, primary);
						}
					}
				}
			}
		}
		a.logo {
			background-image: url(../images/logo-white.svg);
			width: 220px;
			height: 67px;
			img {
				display: none;
			}
		}
		&.scrolled {
			a.logo {
				background-image: none;
				img {
					display: block;
				}
			}
			#nav {
				> ul {
					> li {
						> a, > .nolink {
							color: map_get($theme-colors, middle);
						}
						&.highlight {
							> a {
								color: map_get($theme-colors, primary);
							}
						}
					}
				}
			}
		}
	}
}
#realisations {
	padding: 80px 0 40px 0;
	@include media-breakpoint-down(sm) {
		overflow-x: hidden;
		padding: 40px 0;
	}
	h2 {
		color: map_get($theme-colors, lightpink);
		font-size: 1.2rem;
		font-weight: 600;
		padding: 0 50px 20px 50px;
		@include media-breakpoint-down(sm) {
			padding: 20px;
		}
		a {
			float: right;
			color: #000;
			font-size: .7rem;
			text-transform: uppercase;
			@include media-breakpoint-down(sm) {
				float: none;
				margin-top: 5px;
				display: block;
			}
		}
	}
	.container {
		position: relative;
	}
	.swiper-container {
		width: 75%;
		margin: 0;
		overflow: visible;
		.swiper-slide {
			display: block;
			height: 430px;
			margin-top: 35px;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(0,0,0,.5);
				@include transition(all .2s);
			}
			a {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				text-decoration: none;
				background-repeat: no-repeat;
  				background-size: cover;
      			background-position: center;
			}
			h3 {
				opacity: 0;
			}
			.more {
				opacity: 0;
			}
			&.swiper-slide-active {
				height: 500px;
				margin-top: 0;
				&:after {
					background: rgba(0,0,0,0);
					@include transition(all .2s);
				}
				h3 {
					opacity: 1;
					display: inline-block;
					text-align: left;
					background: #000;
					color: #fff;
					font-size: 1rem;
					font-weight: 600;
					max-width: 240px;
					margin: 230px 0 30px -30px;
					padding: 5px;
					@include media-breakpoint-down(sm) {
						margin: 50px 0 30px 0;
						display: block;
						background: rgba(0,0,0,.5);
					}
				}
				.more {
					opacity: 1;
					display: block;
					font-size: .7rem;
					text-transform: uppercase;
					color: #000;
					text-decoration: underline;
					font-weight: 600;
					margin: 0 0 0 -30px;
					@include media-breakpoint-down(sm) {
						margin: 0;
						display: inline-block;
						background: #000;
						color: #fff;
						padding: 5px;
					}
				}
			}
		}
	}
	.swiper-button-prev, .swiper-button-next {
		background-image: none;
		color: #fff;
		width: auto;
		height: auto;
		padding: 10px 15px;
		font-size: .9rem;
		border-radius: 100%;
		outline: none;
		border: 0;
		@include transition(all .2s);
		&:hover {
			border: 1px solid rgba(255,255,255,.3);
			@include transition(all .2s);
		}
	}
	.swiper-button-prev {
		left: auto;
		right: 10px;
		top: 200px;
	}
	.swiper-button-next {
		top: 250px;
	}
}