
// Form
// =============================================================================

form {

  label {
    display: block;
    margin: 0 0 5px;
    color: #5b5b5b;
    font-size: 1.15rem;
    font-weight: 400;
    line-height: normal;
  }
}

.form-select, .form-text, .form-textarea {
  display: block;
  margin: 0;
  border: 1px solid #cdcdcd;
  border-radius: 0;
  padding: 0 15px;
  width: 100%;
  height: 50px;
  background-color: #fff;
  box-shadow: none;
  color: #5b5b5b;
  font-size: 1.15rem;
  font-family: $font-family-sans-serif;
  line-height: normal;
  @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);

  // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214.
  background-image: none;
  background-clip: padding-box;

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Placeholder
  &::placeholder {
    color: #5b5b5b;

    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: #eceeef;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.error {
    border-color: darken(#f2dede, 5%);
    background-color: lighten(#f2dede, 5%);
  }
}

.form-select {
  &:focus::-ms-value {
    // Suppress the nested default white text on blue background highlight given to
    // the selected option text when the (still closed) <select> receives focus
    // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
    // match the appearance of the native widget.
    // See https://github.com/twbs/bootstrap/issues/19398.
    color: #5b5b5b;
    background-color: #fff;
  }
}

// Make file inputs better match text inputs by forcing them to new lines.
.form-file,
.form-range {
  display: block;
  max-width: 100%;
}

.form-select[multiple], .form-select[size], .form-textarea {
  height: auto;
}


// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

.form-item {
  margin-bottom: 25px;
}


// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.form-type-radio, .form-type-checkbox {
  display: block;
  position: relative;
  margin-bottom: 10px;

  &.disabled {
    .form-check-label {
      color: #636c72;
      cursor: not-allowed;
    }
  }

  label {
    padding-left: 20px;
    margin-bottom: 0; // Override default `<label>` bottom margin
    cursor: pointer;
  }

  input {
    position: absolute;
    margin-top: 5px;

    &:only-child {
      position: static;
    }

    &.error {
      & + label {
        color: #a94442;
      }
    }
  }
}

fieldset {
  margin-bottom: 25px !important;
  height: auto !important;
  legend {
    margin: 0 0 25px;
  }
}


// Custom

