// -------------------------------------------------------------
//   Navigation
// -------------------------------------------------------------
#nav {
    > ul {
        margin: 10px 0 0 0;
        padding: 0;
        list-style: none;
        font-size: 0;
        @include clearfix;

        > li {
            display: inline-block;
            position: relative;
            margin-left: 0;
            @include media-breakpoint-down(lg) {
                display: block;
            }
            > a, > .nolink {
                display: block;
                position: relative;
                padding: 15px 0;
                color: map-get($theme-colors, dark);
                font-size: 0.8rem;
                font-weight: 600;
                line-height: 1.2em;
                text-decoration: none;
                @include transition(padding .2s);
                @include media-breakpoint-down(lg) {
                    font-size: 1rem;
                    color: map-get($theme-colors, dark) !important;
                }
            }
            & + li {
                margin-left: 15px;

                @include media-breakpoint-down(lg) {
                    margin-left: 0;
                }
            }

            &:hover, &.active, &.active-trail {
                > a, > .nolink {
                    color: map_get($theme-colors, primary) !important; 
                } 
            }

            @include media-breakpoint-up(lg) {
                &:hover {
                    > ul {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            // Niv 2
            > ul {
                position: absolute;
                z-index: 1000;
                top: 100%;
                left: 0;
                margin: 0;
                padding: 0;
                width: 210px;
                min-width: 100%;
                background-color: #fff;
                list-style: none;
                text-align: left;
                opacity: 0;
                visibility: hidden;
                @include transition(all .35s);

                @include media-breakpoint-down(lg) {
                    display: none;
                    position: relative;
                    top: auto;
                    left: auto;
                    width: 100%;
                    background-color: transparentize(#fff,0.8);
                    opacity: 1;
                    visibility: visible;
                    text-align: center;
                }
                > li {
                    margin: 0;
                    > a, > .nolink {
                        display: block;
                        padding: 10px 15px;
                        color: map_get($theme-colors, primary);
                        font-size: 0.8rem;
                        font-weight: 600;
                        line-height: 1.2em;
                        text-decoration: none;
                        @include transition(all .2s);
                    }
                    & + li {
                        border-top: 1px solid transparentize(#fff,0.85);
                    }
                    &:hover, &.active {
                        > a, > .nolink {
                            background-color: map-get($theme-colors, primary);
                            color: #fff;
                        }
                    }
                }
            }
            &.highlight {
                a {
                    background: #fff;
                    color: map-get($theme-colors, primary);
                    padding-left: 10px;
                    padding-right: 10px;
                    border-radius: 3px;
                    font-weight: 700;
                    @include media-breakpoint-down(sm) {
                        background: map-get($theme-colors, primary);
                        color: #fff !important;
                    }
                }
            }
            &.fb-link {
                a {
                    &:before {
                        content:'\f39e';
                        font-weight: 400;
                        font-family: $font-awesome-brands;
                    }
                    span {
                        display: none;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(lg) {
        > .sublvl {
            > li {
                &.expanded {
                    position: relative;
                    overflow: hidden;
                }
                .expand {
                    display: block;
                    position: absolute;
                    z-index: 5;
                    top: 0;
                    right: 0;
                    width: 60px;
                    height: 60px;
                    &:before {
                        content: '+';
                        display: block;
                        position: absolute;
                        top: 50%;
                        margin-top: -12px;
                        width: 100%;
                        color: #fff;
                        font-size: 24px;
                        line-height: 1em;
                        text-align: center;
                    }
                }
                &.open {
                    .expand {
                        &:before {
                            content: '-';
                        }
                    }
                    > ul {
                        display: block;
                    }
                }
            }
        }
    }
}

.main-header.scrolled {
    #nav {
        > ul {
            > li {
                &.highlight {
                    > a, > .nolink {
                        background-color: rgba(0,0,0,.05);
                        @include transition(all .2s);
                    }
                }
            }
        }
    }
    .trigger-menu {
        top: 30px;
        @include transition(all .2s);
    }
}


.trigger-menu {
  width: 40px;
  height: 25px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: tranform .5s ease-in-out;
  -moz-transition: tranform .5s ease-in-out;
  -o-transition: tranform .5s ease-in-out;
  transition: tranform .5s ease-in-out;
  margin: 15px auto 0 auto;
  position: absolute;
  right: 40px;
  top: 40px;
  z-index: 12;
  span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: map-get($theme-colors, primary);
    border-radius: 5px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2) {
      top: 10px;
    }
    &:nth-child(3) {
      top: 20px;
    }
  }

  &.open {
    position: fixed;
    top: 30px;
    @include transition(all .2s);
    span {
      &:nth-child(1) {
        top: 10px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }
      &:nth-child(2) {
        opacity: 0;
        left: -60px;
      }
      &:nth-child(3) {
        top: 10px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
  }
}

@include media-breakpoint-down(lg) {
    nav#nav {
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 60px 0;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        background-color: transparentize(#fff,0.02);
        overflow-x: auto;
        overflow-y: scroll;
        @include transition(all .35s);
    }
}

body.open-menu {
    @include media-breakpoint-down(lg) {
        height: 100%;
        overflow: hidden;
        nav#nav {
            visibility: visible;
            opacity: 1;
        }
    }
}
.mobile-menu {
    ul {
        padding: 20px 0 0 0;
        margin-top: 20px;
        border-top: 2px solid #eee;
        li {
            list-style: none;
            a {
                font-size: 1rem;
                color: map-get($theme-colors, dark);
                display: block;
                padding: 10px 0;
                text-decoration: none;
                &.active {
                    color: map_get($theme-colors, primary);
                }
            }
        }
    }
}