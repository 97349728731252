// Button
// =============================================================================

.btn {
  display: inline-block;
  border: 3px solid transparent;
  border-radius: 5px;
  padding: 10px 20px;
  box-shadow: none !important;
  font-family: $font-family-sans-serif;
  font-size: 0.94rem;
  font-weight: 600;
  line-height: 1.8em;
  white-space: normal;
  text-decoration: none !important;
  @include transition(all 0.35s);
  &.btn1 {
    background-color: map-get($theme-colors, primary);
    border-color: map-get($theme-colors, primary);
    color: #fff;
    &:hover {
      background-color: darken(map-get($theme-colors, primary), 10%);
      border-color: darken(map-get($theme-colors, primary), 10%);
      color: #fff;
    }
  }
  &.btn2 {
    background-color: #fff;
    border: 1px solid transparent;
    font-weight: 700;
    color: map-get($theme-colors, primary);
    &:hover {
      background-color: map-get($theme-colors, primary);
      color: #fff;
    }
  }
  &.btn3 {
    background-color: map_get($theme-colors, middle);
    border: 1px solid #fff;
    color: #fff;
    &:hover {
      background-color: #fff;
      border: 1px solid map-get($theme-colors, primary);
      color: map-get($theme-colors, primary);
    }
  }
  &.btn4 {
    background-color: #fff;
    border: 1px solid map-get($theme-colors, primary);
    color: map-get($theme-colors, primary);
    &:hover {
      background-color: map_get($theme-colors, middle);
      border: 1px solid #fff;
      color: #fff;
    }
  }
}

input[type="submit"] {
  @extend .btn;
  @extend .btn-primary;
  cursor: pointer;
}
