.content-evidence {
	position: relative;
	.evidence-text {
		width: 50%;
		padding: 120px 60px;
		font-size: .8rem;
		@include media-breakpoint-down(sm) {
			width: 100%;
			padding: 20px 10px;;
		}
	}
	.evidence-image {
		width: 50%;
		height: 100%;
		position: absolute;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		@include media-breakpoint-down(sm) {
			position: static;
			width: 100%;
			height: 300px;
		}
	}
	&.left {
		.evidence-text {
			margin-left: 50%;
			@include media-breakpoint-down(sm) {
				margin-left: 0;
			}
		}
		.evidence-image {
			left: 0;
		}
	}
	&.right {
		.evidence-text {
			margin-left: 0;
		}
		.evidence-image {
			left: 50%;
		}
	}
}