.page-panier {
  .breadcrumb {
    display: none;
  }
  .content-banner {
    height: 480px;
    h1 {
      padding-top: 170px;
      padding-bottom: 10px;
    }
    p {
      margin-bottom: 5px;
      color: #fff;
      font-size: 1.1rem;
    }
  }
  .messages {
    border-radius: 0;
    &.error {
      background-color: red;
      font-weight: 700;
      padding: 20px;
    }
    .close {
      opacity: 1;
    }
  }
}
.steps {
  padding: 0;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }
  li {
    width: 25%;
    list-style: none;
    padding: 20px 10px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.7rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    display: inline-block;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    &:before {
      margin-right: 3px;
    }
    &:nth-child(1) {
      &:before {
        content: "1.";
      }
    }
    &:nth-child(2) {
      &:before {
        content: "2.";
      }
    }
    &:nth-child(3) {
      &:before {
        content: "3.";
      }
    }
    &:nth-child(4) {
      &:before {
        content: "4.";
      }
    }
    &.active {
      background-color: rgba(255, 255, 255, 1);
      color: map_get($theme-colors, primary);
    }
    &.cart-step-done {
      background-color: rgba(255, 255, 255, 1);
      color: map_get($theme-colors, dark);
      opacity: 0.8;
    }
  }
}
#cart {
  h2 {
    color: #fff;
    font-size: 1.4rem;
  }
  a.min,
  a.plus {
    font-size: 0.75rem;
    display: inline-block;
    padding: 5px;
    line-height: 1;
    &:hover {
      color: map_get($theme-colors, primary);
    }
  }
  table {
    tbody {
      tr {
        &:nth-child(odd) {
          td {
            background-color: map-get($theme-colors, light);
          }
        }
      }
    }
  }
}
.total-bloc {
  text-align: right;
  p {
    color: #fff;
    margin-bottom: 5px;
    strong {
      display: inline-block;
      width: 160px;
      @include media-breakpoint-down(md) {
        width: auto;
      }
    }
    &.eco {
      font-style: italic;
      font-size: 0.75rem;
    }
  }
}
.codes {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }
  fieldset {
    width: 48%;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    .fieldset-wrapper {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      .form-item {
        margin-bottom: 0;
        width: calc(100% - 130px);
      }
      input[type="submit"] {
        height: 50px;
        border: 5px solid #fff;
        background-color: map_get($theme-colors, middle);
        border-radius: 0;
        padding: 0 20px;
        margin-left: -1px;
        width: 130px;
      }
      .form-text {
        border: 1px solid #fff;
        outline: none;
      }
      .legend {
        color: #fff;
        font-size: 0.8rem;
        margin-top: 10px;
        font-style: italic;
      }
    }
    label {
      color: #fff;
      font-size: 0.9rem;
      font-weight: 700;
      margin-bottom: 15px;
    }
  }
}
#ciaco-cart-form {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
  > div {
    > input[type="submit"] {
      float: right;
    }
  }
}
.other-list-container {
  text-align: center;
}
.back-cart {
  padding: 10px 30px;
  margin-left: 10px;
  border: 0;
  &:hover {
  }
}
a.other-lists {
  display: inline-block;
  padding: 10px 30px;
  background-color: #fff;
  color: map-get($theme-colors, primary);
  margin: 0 auto 20px auto;
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 700;
  border-radius: 5px;
  &:before {
    content: "\f0ca";
    font-family: $font-awesome;
    margin-right: 5px;
  }
}
